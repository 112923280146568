// Imports => React
import React from 'react';
import { inject, observer } from 'mobx-react';
import { withNamespaces } from 'react-i18next';
import { Fade } from 'react-reveal';

// Imports => Controller
import AcParticipationsOverviewController from './ac-participations-overview.controller.js';

// Imports => Molecules
import AcParticipationCard from '@molecules/ac-participation-card/ac-participation-card.web.js';

// Imports => Atoms
import { AcContainer, AcRow, AcColumn } from '@atoms/ac-grid';
import AcSection from '@atoms/ac-section/ac-section.web.js';
import AcHeading from '@atoms/ac-heading/ac-heading.web.js';
import AcRichContent from '@atoms/ac-rich-content/ac-rich-content.web.js';
import AcLoader from '@atoms/ac-loader/ac-loader.web.js';

@inject('store')
@observer
class AcParticipationsOverview extends AcParticipationsOverviewController {
  render() {
    const { impersonated } = this.store.auth;
    const { all: Projects, loading } = this.store.projects;
    const { preferred_account } = this.store.auth.user;
    const { currentLanguage } = this.store.settings;

    return (
      <div
        className={
          !impersonated
            ? this.getPageClassNames()
            : this.getImpersonateClassNames()
        }
        key={`${preferred_account}-${currentLanguage}`}
      >
        <AcSection theme={'white'}>
          <AcContainer fluid={false}>
            <AcRow>
              <AcColumn>
                <AcHeading rank={4}>Lopende inschrijvingen</AcHeading>
              </AcColumn>
            </AcRow>

            <AcRow>
              {!loading &&
                Projects &&
                Projects.map(project => (
                  <AcColumn
                    xs={12}
                    key={`project-${project.id}`}
                    className={'h-display--flex'}
                  >
                    <Fade>
                      <AcParticipationCard
                        orientation={'vertical'}
                        id={project.id}
                        project={project}
                      />
                    </Fade>
                  </AcColumn>
                ))}

              {!loading && (!Projects || Projects.length === 0) && (
                <AcColumn>
                  <Fade>
                    <AcRichContent
                      content={'<p>Geen inschrijvingen gevonden</p>'}
                      className={'h-text--color-medium h-text--italic'}
                    />
                  </Fade>
                </AcColumn>
              )}

              {loading && (
                <AcColumn className={'h-padding-y-35'}>
                  <Fade>
                    <AcLoader theme={'pitch'} cover={true} loading={loading} />
                  </Fade>
                </AcColumn>
              )}
            </AcRow>
          </AcContainer>
        </AcSection>
      </div>
    );
  }
}

export default withNamespaces()(AcParticipationsOverview);
