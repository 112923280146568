// Imports => React
import React from 'react';
import clsx from 'clsx';

const _CLASSES = {
  PAGE: 'ac-page',
  MAIN: 'ac-news-article',
  META: 'ac-news-article__meta',
  INTRODUCTION: 'ac-news-article__introduction',
  BODY: 'ac-news-article__body',
  IMPERSONATE: 'ac-page--impersonate-active',
};

let _timer = null;

class AcNewsArticleController extends React.Component {
  constructor(props) {
    super(props);

    const { id } = this.props.match.params;

    this.store = props.store;

    this.state = {
      id: id || false,
      account: this.store.auth.currentUser.preferred_account,
    };
  }

  componentDidMount() {
    this.init();
  }

  componentDidUpdate(nextProps, prevState) {
    if (_timer) clearTimeout(_timer);

    const { preferred_account } = this.store.auth.currentUser;
    const { currentLanguage } = this.store.settings;

    let account_changed = true;
    let locale_changed = true;

    // Refresh project data after the locale/currentLanguage has changed
    const a = preferred_account && preferred_account.id;
    const b = prevState.account && prevState.account.id;
    if (a === b) account_changed = false;

    if (
      (currentLanguage === null && prevState.locale === null) ||
      (currentLanguage !== null &&
        prevState.locale !== null &&
        currentLanguage === prevState.locale)
    )
      locale_changed = false;

    if (!account_changed && !locale_changed) return;

    this.setState(
      {
        account: preferred_account,
        locale: currentLanguage,
      },
      () => {
        _timer = setTimeout(() => {
          this.init();
        }, 1000);
      }
    );
  }

  init = async () => {
    const { id } = this.state;
    if (!id) return;
    await this.store.news
      .fetch(id)
      .then(() => {})
      .catch(error => {
        const { push } = this.props.routing;

        push('/news');
      });
  };

  carouselCallback = (event, label, id) => {
    const { single: Article } = this.store.news;

    let target = 0;

    if (Article.news_images && id) {
      Article.news_images.forEach((item, x) => {
        if (item.id === id) {
          target = x;
          return;
        }
      });

      this.openLightbox(target);
    }
  };

  openLightbox = target => {
    const { single: Article } = this.store.news;

    this.store.ui.setLightboxTarget(target);
    this.store.ui.setLightboxCollection(Article.news_images);
    this.store.ui.openLightbox();
  };

  getBodyClassNames() {
    return clsx(_CLASSES.BODY);
  }

  getIntroClassNames() {
    return clsx(_CLASSES.INTRODUCTION);
  }

  getMetaClassNames() {
    return clsx(_CLASSES.META);
  }

  getStyleClassNames() {
    return clsx(_CLASSES.MAIN);
  }

  getPageClassNames() {
    return clsx(_CLASSES.PAGE, this.getStyleClassNames());
  }
  getImpersonateClassNames() {
    return clsx(_CLASSES.IMPERSONATE, this.getStyleClassNames());
  }
}

AcNewsArticleController.propTypes = {};

AcNewsArticleController.defaultProps = {};

export default AcNewsArticleController;
