// Imports => React
import React from 'react';
import { inject, observer } from 'mobx-react';
import loadable from '@loadable/component';

// Imports => Controller
import AcPortfolioController from './ac-portfolio.controller.js';

// Imports => Molecules
const AcProjectCard = loadable(() =>
  import('@molecules/ac-project-card/ac-project-card.web.js')
);

// Imports => Atoms
import { AcContainer, AcRow, AcColumn } from '@atoms/ac-grid';
const AcSection = loadable(() => import('@atoms/ac-section/ac-section.web.js'));

const AcLoader = loadable(() => import('@atoms/ac-loader/ac-loader.web.js'));
const AcSwitch = loadable(() => import('@atoms/ac-switch/ac-switch.web.js'));

const AcHeading = loadable(() => import('@atoms/ac-heading/ac-heading.web.js'));
const AcRichContent = loadable(() =>
  import('@atoms/ac-rich-content/ac-rich-content.web.js')
);

@inject('store')
@observer
class AcPortfolio extends AcPortfolioController {
  renderProjects = collection => {
    const { switcher } = this.state;

    let result = [];

    let len = collection.length;
    let n = 0;

    for (n; n < len; n++) {
      const project = collection[n];

      const object = (
        <AcColumn
          xs={12}
          sm={12}
          md={switcher.value === 'ongoing' ? 6 : 12}
          key={project.id}
        >
          <AcProjectCard
            orientation={switcher.value === 'ongoing' ? 'vertical' : 'horizontal'}
            project={project}
          />
        </AcColumn>
      );

      result.push(object);
    }

    return result;
  };

  render() {
    const { impersonated } = this.store.auth;
    const { switcher } = this.state;
    const { current_projects: Projects, loading } = this.store.projects;
    const { preferred_account } = this.store.auth.user;
    const { currentLanguage } = this.store.settings;

    return (
      <div
        className={
          !impersonated ? this.getPageClassNames() : this.getImpersonateClassNames()
        }
        key={`${preferred_account}-${currentLanguage}`}
      >
        <>
          <AcSection theme={'white'}>
            <AcContainer fluid={false}>
              <AcRow>
                <AcColumn>
                  <AcSwitch
                    theme={switcher.theme}
                    name={switcher.name}
                    options={switcher.options}
                    value={switcher.value}
                    callback={this.handleSwitchCallback}
                  />
                </AcColumn>
              </AcRow>
            </AcContainer>
          </AcSection>

          {!loading && (
            <AcSection theme={'white'} className={'h-padding-y-25'}>
              <AcContainer fluid={false}>
                <AcRow>
                  <AcColumn>
                    <AcHeading rank={4}>{this.getCollectionTitle()}</AcHeading>
                  </AcColumn>
                </AcRow>

                <AcRow>
                  {!loading && Projects && this.renderProjects(Projects)}
                </AcRow>

                {!loading && (!Projects || Projects.length === 0) && (
                  <AcRow>
                    <AcColumn>
                      <AcRichContent
                        content={'<p>Geen projecten gevonden</p>'}
                        className={'h-text--color-medium h-text--italic'}
                      />
                    </AcColumn>
                  </AcRow>
                )}

                {loading && (
                  <AcRow>
                    <AcColumn className={'h-padding-y-35'}>
                      <AcLoader theme={'pitch'} cover={true} loading={loading} />
                    </AcColumn>
                  </AcRow>
                )}
              </AcContainer>
            </AcSection>
          )}

          {loading && (
            <AcSection theme={'white'} fullheight={true}>
              <AcLoader theme={'pitch'} cover={true} loading={loading} />
            </AcSection>
          )}
        </>
      </div>
    );
  }
}

export default AcPortfolio;
