// Imports => React
import React from 'react';
import clsx from 'clsx';

// Imports => Utilities
import ScrollTo from '@utils/ac-scrollto.js';

// Imports => Constants
import STEPS from '@constants/participation.steps.constants.js';

const _CLASSES = {
  PAGE: 'ac-page',
  MAIN: 'ac-participation',
  FORMWRP: 'ac-card__content ac-participation__card__content',
  STEPPER: 'ac-participation__stepper',
  IMPERSONATE: 'ac-page--impersonate-active',
};

class AcParticipationController extends React.Component {
  constructor(props) {
    super(props);

    this.store = props.store;
    this.steps = [];

    const projectid = props.match.params.projectid;

    this.store.participation.reset();
    this.store.participation.set('project_id', projectid);
    this.store.participation.set('contact', this.store.auth.user);

    this.filterSteps = this.filterSteps.bind(this);
    this.getSteps = this.getSteps.bind(this);
    this.renderStep = this.renderStep.bind(this);

    this.state = {
      locale: this.store.settings.language,
      projectid: projectid,
      current_step: 0,
    };

    this.handleBackButton = this.handleBackButton.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);

    this.saveParticipation = this.saveParticipation.bind(this);
    this.getParticipation = this.getParticipation.bind(this);

    this.init = this.init.bind(this);
  }

  componentDidMount() {
    this.init();
  }

  async init() {
    const { projectid } = this.state;

    await this.store.projects
      .fetch(projectid)
      .then(() => {})
      .catch(error => {
        const { push } = this.props.routing;

        push('/portfolio');
      });
  }

  handleBackButton(event) {
    const { current_step } = this.state;

    const previousStep = current_step - 1 < 0 ? current_step : current_step - 1;

    this.setState({ current_step: previousStep }, () => {
      const $scroller = document.getElementById('ac-scroller');
      const $card = document.getElementById('ac-participation-form-card');

      ScrollTo($scroller, $card, 150);
    });
  }

  async handleSubmit(event, step) {
    const { current_step } = this.state;

    let next_step =
      current_step + 1 >= STEPS.BASIC.length ? current_step : current_step + 1;

    // console.group('[View: Participation] => fn.HandleSubmit');
    // console.log('Current step: ', current_step);
    // console.log('Next step: ', next_step);
    // console.log('Steps: ', this.steps);
    // console.log('Steps => Next: ', this.steps[next_step]);
    // console.groupEnd();

    if (this.steps && this.steps[next_step].action === 'SAVE')
      await this.saveParticipation();

    this.setState({ current_step: next_step }, () => {
      const $scroller = document.getElementById('ac-scroller');
      const $card = document.getElementById('ac-participation-form-card');

      ScrollTo($scroller, $card, 150);
    });
  }

  async saveParticipation(event) {
    if (event) event.preventDefault();

    await this.store.participation.save().then(async response => {
      this.store.participation.set(
        'participation_id',
        response.participation_local_id
      );
      await this.getParticipation();
    });
  }

  async getParticipation() {
    const { participation_id } = this.store.participation.data;

    if (!participation_id || participation_id === null) return;

    await this.store.participation.fetch(participation_id);
  }

  getSteps(active) {
    const { current_step } = this.state;

    let _steps = this.filterSteps();

    const result = _steps.map((step, n) => {
      return {
        ...step,
        current: n === current_step,
        finished: n < current_step,
      };
    });

    this.steps = result;

    return result;
  }

  filterSteps() {
    const COLLECTION = STEPS.BASIC;

    const filtered = STEPS.ALL.filter(ANCHOR => {
      return COLLECTION.indexOf(ANCHOR.key) > -1;
    });

    const result = filtered.slice().sort((a, b) => {
      return COLLECTION.indexOf(a.key) - COLLECTION.indexOf(b.key);
    });

    return result;
  }

  renderStep(_steps, current_step) {
    if (!_steps[current_step]) return null;

    const current = _steps[current_step];
    const Tag = current.component;

    return (
      <Tag
        key={`ac-participation-step-${current.id}`}
        step={current}
        callback={this.handleSubmit}
        backbtn={this.handleBackButton}
      />
    );
  }

  getStepperClassNames() {
    return clsx(_CLASSES.STEPPER);
  }

  getFormWrpClassNames() {
    return clsx(_CLASSES.FORMWRP);
  }

  getStyleClassNames() {
    return clsx(_CLASSES.MAIN);
  }

  getPageClassNames() {
    return clsx(_CLASSES.PAGE, this.getStyleClassNames());
  }

  getImpersonateClassNames() {
    return clsx(_CLASSES.IMPERSONATE, this.getStyleClassNames());
  }
}

AcParticipationController.propTypes = {};

AcParticipationController.defaultProps = {};

export default AcParticipationController;
