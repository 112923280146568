// Imports => React
import React from 'react';
import { inject, observer } from 'mobx-react';
import { withNamespaces } from 'react-i18next';
import { Fade } from 'react-reveal';

// Imports => Controller
import AcNewsArchiveController from './ac-news-archive.controller.js';

// Imports => Molecules
import AcNewsCard from '@molecules/ac-news-card/ac-news-card.web.js';

// Imports => Atoms
import { AcContainer, AcRow, AcColumn } from '@atoms/ac-grid';
import AcSection from '@atoms/ac-section/ac-section.web.js';
import AcHeading from '@atoms/ac-heading/ac-heading.web.js';
import AcRichContent from '@atoms/ac-rich-content/ac-rich-content.web.js';
import AcLoader from '@atoms/ac-loader/ac-loader.web.js';

@inject('store')
@observer
class AcNewsArchive extends AcNewsArchiveController {
  render() {
    const { impersonated } = this.store.auth;
    const { all: News, loading: NewsLoading } = this.store.news;
    const { preferred_account } = this.store.auth.user;

    return (
      <div
        className={
          !impersonated
            ? this.getPageClassNames()
            : this.getImpersonateClassNames()
        }
        key={preferred_account}
      >
        <AcSection theme={'white'}>
          <AcContainer fluid={false}>
            <AcRow>
              <AcColumn>
                <AcHeading rank={4}>Nieuws</AcHeading>
              </AcColumn>
            </AcRow>

            <AcRow>
              {!NewsLoading &&
                News &&
                News.map(post => (
                  <AcColumn
                    xs={12}
                    sm={6}
                    key={`news-item-${post.id}`}
                    className={'h-display--flex'}
                  >
                    <Fade>
                      <AcNewsCard
                        orientation={'vertical'}
                        id={post.id}
                        post={post}
                      />
                    </Fade>
                  </AcColumn>
                ))}

              {!NewsLoading && (!News || News.length === 0) && (
                <AcColumn>
                  <Fade>
                    <AcRichContent
                      content={'<p>Geen nieuwsberichten gevonden</p>'}
                      className={'h-text--color-medium h-text--italic'}
                    />
                  </Fade>
                </AcColumn>
              )}

              {NewsLoading && (
                <AcColumn className={'h-padding-y-35'}>
                  <Fade>
                    <AcLoader
                      theme={'pitch'}
                      cover={true}
                      loading={NewsLoading}
                    />
                  </Fade>
                </AcColumn>
              )}
            </AcRow>
          </AcContainer>
        </AcSection>
      </div>
    );
  }
}

export default withNamespaces()(AcNewsArchive);
