// Imports => React
import React from 'react';
import { inject, observer } from 'mobx-react';
import { withNamespaces } from 'react-i18next';

// Imports => Controller
import AcProjectController from './ac-project.controller.js';

// Imports => Molecules
import AcKPIGroup from '@molecules/ac-kpi-group/ac-kpi-group.web.js';

// Imports => Components
import AcHero from '@components/ac-hero/ac-hero.web.js';
import AcAsideNavigation from '@components/ac-aside-navigation/ac-aside-navigation.web.js';

// Imports => Atoms
import { AcContainer, AcRow, AcColumn } from '@atoms/ac-grid';
import AcSection from '@atoms/ac-section/ac-section.web.js';

import AcLoader from '@atoms/ac-loader/ac-loader.web.js';

import { MATCH_ANCHORS } from '@constants/project.anchors.constants.js';

@inject('routing', 'store')
@observer
class AcProject extends AcProjectController {
  render() {
    const { impersonated } = this.store.auth;
    const { current_project: Project, loading } = this.store.projects;
    const { anchors } = this.state;

    const heroSubtitle = () => {
      let result = '';

      if (!Project) return result;

      result = [];

      if (Project.city) result.push(Project.city);
      if (Project.region === 'USA' && Project.state) result.push(Project.state);
      if (Project.country && Project.country.name)
        result.push(Project.country.name);

      return result.join(', ');
    };

    // Check availability of content
    const availableContentArray = anchors.map(data => ({
      // Match anchors to API

      rel: data.rel,
      available:
        Project &&
        (Project[MATCH_ANCHORS[data.rel]].length ||
          Project[MATCH_ANCHORS[data.rel]].body),
    }));

    // Filter out empty content elements
    const filteredAnchors = anchors.filter(a =>
      availableContentArray.filter(b => b.available).some(c => c.rel === a.rel)
    );

    const renderAnchors = () => {
      if (!filteredAnchors) return null;
      if (!filteredAnchors.length) return null;

      let x = 0;
      let len = filteredAnchors.length;
      let result = [];

      for (x; x < len; x++) {
        const anchor = filteredAnchors[x];

        const Tag = anchor.component;
        const obj = <Tag project={Project} section={anchor} key={anchor.id} />;

        result.push(obj);
      }

      return result;
    };

    const renderAsideNavigation = () => {
      if (!filteredAnchors) return null;

      return <AcAsideNavigation anchors={filteredAnchors} />;
    };

    return (
      <div
        className={
          !impersonated
            ? this.getPageClassNames()
            : this.getImpersonateClassNames()
        }
      >
        <>
          {!loading && Project !== null && typeof Project !== 'undefined' && (
            <>
              {Project.project_featured_images &&
                Project.project_featured_images.length > 0 && (
                  <AcHero
                    title={Project.name}
                    subtitle={heroSubtitle()}
                    nectar={Project.project_featured_images}
                    showGoogleMaps={Project.show_google_maps}
                    urlGoogleMaps={Project.url_google_maps}
                  />
                )}
              {!Project.project_featured_images ||
                (Project.project_featured_images.length === 0 && (
                  <AcHero
                    title={Project.name}
                    subtitle={heroSubtitle()}
                    image={Project.cover}
                    showGoogleMaps={Project.show_google_maps}
                    urlGoogleMaps={Project.url_google_maps}
                  />
                ))}
              <AcContainer fluid={true} className={'ac-scroll-container'}>
                <AcRow>
                  {renderAsideNavigation()}

                  {Project && Project.kpis && (
                    <AcSection theme={'white'}>
                      <AcContainer fluid={false}>
                        <AcRow>
                          <AcColumn>
                            <AcKPIGroup
                              autoAlign={Project.kpis.length <= 3}
                              currency={this.getCurrency()}
                              kpis={Project.kpis}
                              projectStatus={Project.status}
                              sortForPortfolio={true}
                            />
                          </AcColumn>
                        </AcRow>
                      </AcContainer>
                    </AcSection>
                  )}

                  {renderAnchors()}
                </AcRow>
              </AcContainer>
            </>
          )}

          {(loading || Project === null || typeof Project === 'undefined') && (
            <AcSection theme={'white'} fullheight={true}>
              <AcLoader theme={'pitch'} cover={true} loading={loading} />
            </AcSection>
          )}
        </>
      </div>
    );
  }
}

export default withNamespaces()(AcProject);
