// Imports => React
import React from 'react';
import { inject, observer } from 'mobx-react';
import { withNamespaces } from 'react-i18next';

// Imports => Assets
import REALIANCEHero from '@assets/images/hero/realiance-hero-bw-2x.jpg';

// Imports => Utilities
import AcFormatDate from '@utils/ac-format-date.js';

// Imports => Controller
import AcNewsArticleController from './ac-news-article.controller.js';

// Imports => Components
import AcHero from '@components/ac-hero/ac-hero.web.js';
import AcCarousel from '@components/ac-carousel/ac-carousel.web.js';

// Imports => Atoms
import { AcContainer, AcRow, AcColumn } from '@atoms/ac-grid';
import AcSection from '@atoms/ac-section/ac-section.web.js';
import AcDivider from '@atoms/ac-divider/ac-divider.web.js';
import AcRichContent from '@atoms/ac-rich-content/ac-rich-content.web.js';
import AcLoader from '@atoms/ac-loader/ac-loader.web.js';

@inject('store')
@observer
class AcNewsArticle extends AcNewsArticleController {
  render() {
    const { impersonated } = this.store.auth;
    const { single: Article, loading } = this.store.news;
    const { preferred_account } = this.store.auth.user;

    let cover = {
      responsive: { xl: REALIANCEHero },
    };

    if (
      Article.featured_image &&
      Article.featured_image !== null &&
      !Array.isArray(Article.featured_image)
    ) {
      cover = Article.featured_image;
    }

    return (
      <div
        className={
          !impersonated
            ? this.getPageClassNames()
            : this.getImpersonateClassNames()
        }
        key={preferred_account}
      >
        {!loading && Article && (
          <>
            <AcHero title={Article.title && Article.title} image={cover} />

            <AcSection
              theme={'white'}
              className={'h-text--align-left'}
            >
              <AcContainer fluid={false}>
                <AcRow>
                  <AcColumn>
                    <AcRichContent
                      content={`Geplaatst op ${AcFormatDate(
                        Article.date,
                        'DD-MM-YYYY',
                        'DD MMMM Y'
                      )}`}
                      className={this.getMetaClassNames()}
                    />
                  </AcColumn>
                </AcRow>

                {Article.description && Article.description !== '' && (
                  <AcRow className={'h-margin-top-35'}>
                    <AcColumn>
                      <AcRichContent
                        content={Article.description}
                        className={this.getIntroClassNames()}
                      />
                    </AcColumn>
                  </AcRow>
                )}

                <AcRow>
                  <AcColumn>
                    <AcDivider />
                  </AcColumn>
                </AcRow>

                <AcRow>
                  <AcColumn>
                    <AcRichContent
                      content={Article.content}
                      className={this.getBodyClassNames()}
                    />
                  </AcColumn>
                </AcRow>
              </AcContainer>

              {Article.news_images && Article.news_images.length > 0 && (
                <>
                  <AcCarousel
                    items={Article.news_images}
                    infinite={true}
                    callback={this.carouselCallback}
                  />
                </>
              )}
            </AcSection>
          </>
        )}

        {(loading || Article === null || typeof Article === 'undefined') && (
          <AcSection theme={'white'} fullheight={true}>
            <AcLoader theme={'pitch'} cover={true} loading={loading} />
          </AcSection>
        )}
      </div>
    );
  }
}

export default withNamespaces()(AcNewsArticle);
