// Imports => React
import React from 'react';
import clsx from 'clsx';

const _CLASSES = {
  PAGE: 'ac-page',
  MAIN: 'ac-participation-overview',
  IMPERSONATE: 'ac-page--impersonate-active',
};

let _timer = null;

class AcParticipationsOverviewController extends React.Component {
  constructor(props) {
    super(props);

    this.store = props.store;

    this.init = this.init.bind(this);

    this.state = {
      account: this.store.auth.currentUser.preferred_account,
      locale: this.store.settings.currentLanguage,
    };
  }

  componentDidMount() {
    this.init();
  }

  componentDidUpdate(nextProps, prevState) {
    if (_timer) clearTimeout(_timer);

    const { preferred_account } = this.store.auth.currentUser;
    const { currentLanguage } = this.store.settings;

    let account_changed = true;
    let locale_changed = true;

    // Refresh project data after the locale/currentLanguage has changed
    const a = preferred_account && preferred_account.id;
    const b = prevState.account && prevState.account.id;
    if (a === b) account_changed = false;

    if (
      (currentLanguage === null && prevState.locale === null) ||
      (currentLanguage !== null &&
        prevState.locale !== null &&
        currentLanguage === prevState.locale)
    )
      locale_changed = false;

    if (!account_changed && !locale_changed) return;

    this.setState(
      {
        account: preferred_account,
        locale: currentLanguage,
      },
      () => {
        _timer = setTimeout(() => {
          this.init();
        }, 1000);
      }
    );
  }

  async init() {
    await this.store.projects.fetchByStatus('in-application');
  }

  getStyleClassNames() {
    return clsx(_CLASSES.MAIN);
  }

  getPageClassNames() {
    return clsx(_CLASSES.PAGE, this.getStyleClassNames());
  }

  getImpersonateClassNames() {
    return clsx(_CLASSES.IMPERSONATE, this.getStyleClassNames());
  }
}

AcParticipationsOverviewController.propTypes = {};

AcParticipationsOverviewController.defaultProps = {};

export default AcParticipationsOverviewController;
