// Imports => React
import React from 'react';
import clsx from 'clsx';

// Imports => Constants
import {
  ALL,
  NEW,
  IN_APPLICATION,
  ONGOING,
  COMPLETED,
} from '@constants/project.anchors.constants.js';

import AcCurrencySymbol from '@utils/ac-currency-symbol';
import queryString from 'query-string';

const _CLASSES = {
  PAGE: 'ac-page',
  MAIN: 'ac-project',
  IMPERSONATE: 'ac-page--impersonate-active',
};

let _timer = null;

class AcProjectController extends React.Component {
  constructor(props) {
    super(props);

    this.store = props.store;

    this.state = {
      account:
        this.store.auth.current_user &&
        this.store.auth.current_user.preferred_account,
      locale: this.store.settings.currentLanguage,
      projectid: props.match.params.projectid,
      anchors: [],
    };

    this.init = this.init.bind(this);

    this.filterAnchors = this.filterAnchors.bind(this);
    this.getAnchors = this.getAnchors.bind(this);

    this.handleParticipateEvent = this.handleParticipateEvent.bind(this);
  }

  componentDidMount() {
    this.init();
  }

  componentDidUpdate(nextProps, prevState) {
    if (_timer) clearTimeout(_timer);

    const { preferred_account } = this.store.auth.user;
    const { currentLanguage } = this.store.settings;

    let account_changed = true;
    let locale_changed = true;

    // Refresh project data after the locale/currentLanguage has changed
    const a = preferred_account && preferred_account.id;
    const b = prevState.account && prevState.account.id;
    if (a === b) {
      account_changed = false;
    }

    if (
      (currentLanguage === null && prevState.locale === null) ||
      (currentLanguage !== null &&
        prevState.locale !== null &&
        currentLanguage === prevState.locale)
    ) {
      locale_changed = false;
    }

    if (!account_changed && !locale_changed) return;

    this.setState(
      {
        account: preferred_account,
        locale: currentLanguage,
      },
      () => {
        _timer = setTimeout(() => {
          this.init(account_changed);
        }, 1000);
      }
    );
  }

  async init(account_changed) {
    if (typeof account_changed !== 'undefined' && account_changed === true) {
      const { push } = this.props.routing;

      if (push) push('/portfolio');
    } else {
      const { projectid } = this.state;
      const { update } = queryString.parse(window.location.search);
      await this.store.projects
        .fetch(projectid, update)
        .then(() => {
          this.filterAnchors();
        })
        .catch(error => {
          const { push } = this.props.routing;

          push('/portfolio');
        });

      await this.store.projects
        .fetchUpdates(projectid, update)
        .then(() => {
          this.filterAnchors();
        })
        .catch(error => {
          const { push } = this.props.routing;

          push('/portfolio');
        });
    }
  }

  getCurrency = () => {
    const { current_project: Project, loading } = this.store.projects;

    if (!Project || loading) return null;

    if (Project && Project.region === 'USA') return AcCurrencySymbol('USD');
    return AcCurrencySymbol('EUR');
  };

  filterAnchors() {
    const { current_project: Project, loading } = this.store.projects;

    if (!Project || loading) {
      this.setState({ anchors: ALL });
    }

    const collection = this.getAnchors();

    const filtered = ALL.filter(ANCHOR => {
      return collection.indexOf(ANCHOR.key) > -1;
    });

    const result = filtered.slice().sort((a, b) => {
      return collection.indexOf(a.key) - collection.indexOf(b.key);
    });

    this.setState({ anchors: result });
  }

  getAnchors() {
    const { current_project: Project } = this.store.projects;

    let result = false;

    switch (Project.status) {
      case 'new':
        result = NEW;
        break;

      case 'in-application':
        result = IN_APPLICATION;
        break;

      case 'ongoing':
        result = ONGOING;
        break;

      case 'completed':
        result = COMPLETED;
        break;

      default:
        result = ALL;
    }

    return result;
  }

  handleParticipateEvent(event) {
    if (event) event.preventDefault();

    const { projectid } = this.state;
    const { push } = this.props.routing;

    if (!projectid) return;

    push(`/participate/${projectid}`);
  }

  getStyleClassNames() {
    return clsx(_CLASSES.MAIN);
  }

  getPageClassNames() {
    return clsx(_CLASSES.PAGE, this.getStyleClassNames());
  }

  getImpersonateClassNames() {
    return clsx(_CLASSES.IMPERSONATE, this.getStyleClassNames());
  }
}

AcProjectController.propTypes = {};

AcProjectController.defaultProps = {};

export default AcProjectController;
