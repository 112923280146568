// Imports => React
import React from 'react';
import clsx from 'clsx';

// Imports => Utils
import AcUUID from '@utils/ac-uuid.js';

const _CLASSES = {
  MAIN: 'ac-authenticate',
  PAGE: {
    MAIN: 'ac-page',
    FULLHEIGHT: 'ac-page--full-height',
    VALIGNCENTER: 'ac-page--v-align-center',
    BACKGROUND: 'ac-page__background',
  },
  LOADER: {
    WRP: 'ac-loader--cover',
  },
};

class AcNewPasswordController extends React.Component {
  constructor(props) {
    super(props);

    this.store = props.store;
    this.store.auth.clearAuthentication();
    this.routing = props.routing;

    const _reset_token = props.match.params.token;

    this.state = {
      username: '',
      password: '',
      reset_token: _reset_token,
      mode: 'reset',
    };

    this.switchMode = this.switchMode.bind(this);

    this.reset = this.reset.bind(this);

    this.handleReset = this.handleReset.bind(this);

    this.handleInputChange = this.handleInputChange.bind(this);

    this.getResetFormProps = this.getResetFormProps.bind(this);
    this.getResetSuccessProps = this.getResetSuccessProps.bind(this);

    this.getPasswordInputOptions = this.getPasswordInputOptions.bind(this);

    this.forms = {
      reset: {
        id: AcUUID(),
        mode: 'reset',
        getProps: this.getResetFormProps,
      },
      'reset-success': {
        id: AcUUID(),
        mode: 'reset-success',
        getProps: this.getResetSuccessProps,
      },
    };
  }

  switchMode(mode) {
    if (!mode) return;

    this.setState({ mode });
  }

  async reset() {
    const { password, reset_token } = this.state;

    if (!password || password === '' || !reset_token || reset_token === '')
      return;

    await this.store.auth
      .reset({
        password: this.state.password,
        reset_token: this.state.reset_token,
      })
      .then(response => {
        this.store.toasters.add({
          variant: 'success',
          delay: 5000,
          title: 'Nieuw wachtwoord opslaan is gelukt!',
          description: false,
        });

        this.switchMode('reset-success');
      })
      .catch(error => {
        this.switchMode('reset');
      });
  }

  handleReset(event) {
    if (event) event.preventDefault();

    this.reset();
  }

  handleInputChange(event, name, value) {
    if (!name) return;
    this.setState({ [name]: value });
  }

  getUsernameInputOptions() {
    return {
      type: 'text',
      value: this.state.username || '',
      name: 'username',
      label: 'Gebruikersnaam',
      callback: this.handleInputChange,
    };
  }

  getPasswordInputOptions() {
    return {
      type: 'password',
      value: this.state.password || '',
      name: 'password',
      label: 'Wachtwoord',
      eye: true,
      indicator: true,
      callback: this.handleInputChange,
    };
  }

  getResetFormProps() {
    return {
      key: this.forms['reset'].id,
      title: 'Nieuw wachtwoord',
      fields: [this.getPasswordInputOptions()],
      actions: [
        {
          text: 'Opslaan',
          button: {
            theme: 'alpha',
            type: 'submit',
            disabled: !this.state.password || this.state.password === '',
          },
          ripple: {
            theme: 'alpha',
            size: 'small',
          },
        },
      ],
      callback: this.handleReset,
    };
  }

  getResetSuccessProps() {
    return {
      key: this.forms['reset-success'].id,
      title: 'Nieuw wachtwoord is opgeslagen',
      introduction: {
        content: `Uw wachtwoord is succesvol gewijzigd. U kunt nu inloggen.`,
      },
      actions: [
        {
          text: 'Naar inloggen',
          button: {
            theme: 'alpha',
            size: 'small',
            variant: 'forgot',

            callback: event => {
              if (event) event.preventDefault();

              const { replace } = this.props.routing;
              replace('/login');
            },
          },
        },
      ],
    };
  }

  getLoaderWrpClassNames() {
    return clsx(_CLASSES.LOADER.WRP);
  }

  getBackgroundClassNames() {
    return clsx(_CLASSES.PAGE.BACKGROUND);
  }

  getPageClassNames() {
    return clsx(
      _CLASSES.PAGE.MAIN,
      _CLASSES.PAGE.FULLHEIGHT,
      _CLASSES.PAGE.VALIGNCENTER,
      this.getStyleClassNames()
    );
  }

  getStyleClassNames() {
    return clsx(_CLASSES.MAIN);
  }
}

AcNewPasswordController.propTypes = {};

AcNewPasswordController.defaultProps = {
  autocomplete: false,
};

export default AcNewPasswordController;
