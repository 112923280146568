// Imports => React
import React from 'react';
import clsx from 'clsx';

// Imports => Utils
import { AcSaveState, AcGetState } from '@utils/ac-storage.js';
import AcUUID from '@utils/ac-uuid.js';

const _CLASSES = {
  PAGE: 'ac-page',
  MAIN: 'ac-portfolio',
  IMPERSONATE: 'ac-page--impersonate-active',
};

let _timer = null;

class AcPortfolioController extends React.Component {
  constructor(props) {
    super(props);

    this.store = props.store;

    const switcher = AcGetState('rea-switcher-value');

    this.state = {
      account:
        this.store.auth.current_user &&
        this.store.auth.current_user.preferred_account,
      locale: this.store.settings.currentLanguage,
      switcher: {
        theme: 'alpha',
        name: `switch-${AcUUID()}`,
        value: switcher || 'ongoing',
        options: [
          {
            label: 'Lopend',
            value: 'ongoing',
          },
          {
            label: 'Afgerond',
            value: 'completed',
          },
        ],
      },
      collections: {
        new: 'Nieuwe projecten',
        'in-application': 'Projecten in aanvraag',
        ongoing: 'Lopende projecten',
        completed: 'Afgeronde projecten',
      },
    };

    this.init = this.init.bind(this);
    this.handleSwitchCallback = this.handleSwitchCallback.bind(this);
  }

  componentDidMount() {
    this.init();
  }

  componentDidUpdate(nextProps, prevState) {
    if (_timer) clearTimeout(_timer);

    const { preferred_account } = this.store.auth.currentUser;
    const { currentLanguage } = this.store.settings;

    let account_changed = true;
    let locale_changed = true;

    // Refresh project data after the locale/currentLanguage has changed
    const a = preferred_account && preferred_account.id;
    const b = prevState.account && prevState.account.id;
    if (a === b) account_changed = false;

    if (
      (currentLanguage === null && prevState.locale === null) ||
      (currentLanguage !== null &&
        prevState.locale !== null &&
        currentLanguage === prevState.locale)
    )
      locale_changed = false;

    if (!account_changed && !locale_changed) return;

    this.setState(
      {
        account: preferred_account,
        locale: currentLanguage,
      },
      () => {
        _timer = setTimeout(() => {
          this.init();
        }, 1000);
      }
    );
  }

  async init() {
    const { switcher } = this.state;

    await this.store.projects.fetchByStatus(switcher.value);
  }

  getCollectionTitle() {
    const { collections, switcher } = this.state;

    return collections[switcher.value];
  }

  handleSwitchCallback(event, name, value) {
    if (!event) return;
    if (!value) return;

    const { switcher } = this.state;

    AcSaveState('rea-switcher-value', value);

    this.setState(
      {
        switcher: {
          ...switcher,
          value: value,
        },
      },
      () => {
        this.init();
      }
    );
  }

  getStyleClassNames() {
    return clsx(_CLASSES.MAIN);
  }

  getPageClassNames() {
    return clsx(_CLASSES.PAGE, this.getStyleClassNames());
  }

  getImpersonateClassNames() {
    return clsx(_CLASSES.IMPERSONATE, this.getStyleClassNames());
  }
}

AcPortfolioController.propTypes = {};

AcPortfolioController.defaultProps = {};

export default AcPortfolioController;
