// Imports => React
import React from 'react';
import clsx from 'clsx';

// Imports => Utils
import AcUUID from '@utils/ac-uuid.js';

const _CLASSES = {
  MAIN: 'ac-authenticate',
  PAGE: {
    MAIN: 'ac-page',
    FULLHEIGHT: 'ac-page--full-height',
    VALIGNCENTER: 'ac-page--v-align-center',
    BACKGROUND: 'ac-page__background',
  },
  LOADER: {
    WRP: 'ac-loader--cover',
  },
};

class AcRegisterController extends React.Component {
  constructor(props) {
    super(props);

    this.store = props.store;
    this.routing = props.routing;

    const _invite_token = props.match.params.token;

    this.state = {
      username: '',
      password: '',
      invite_token: _invite_token,
      mode: 'register',
      submittedOnce: false,
    };

    this.switchMode = this.switchMode.bind(this);

    this.register = this.register.bind(this);

    this.handleRegister = this.handleRegister.bind(this);

    this.handleInputChange = this.handleInputChange.bind(this);

    this.getRegisterFormProps = this.getRegisterFormProps.bind(this);
    this.getRegisterSuccessProps = this.getRegisterSuccessProps.bind(this);

    this.getUsernameInputOptions = this.getUsernameInputOptions.bind(this);
    this.getPasswordInputOptions = this.getPasswordInputOptions.bind(this);

    this.forms = {
      register: {
        id: AcUUID(),
        mode: 'register',
        getProps: this.getRegisterFormProps,
      },
      'register-success': {
        id: AcUUID(),
        mode: 'register-success',
        getProps: this.getRegisterSuccessProps,
      },
      'register-failed': {
        id: AcUUID(),
        mode: 'register-failed',
        getProps: this.getRegisterFailedProps,
      },
    };
  }

  switchMode(mode) {
    if (!mode) return;

    this.setState({ mode });
  }

  async register() {
    const { username, password, invite_token } = this.state;

    if (
      !username ||
      username === '' ||
      !password ||
      password === '' ||
      !invite_token ||
      invite_token === ''
    )
      return;

    this.state.submittedOnce = true;
    await this.store.auth
      .register({
        username: this.state.username,
        password: this.state.password,
        invite_token: this.state.invite_token,
      })
      .then(response => {
        // Toasters might need a rework in the future. Currently, the new meaages won't be shown on success. The code below is kept as a reminder
        // this.store.toasters.add({
        //   variant: 'success',
        //   delay: 5000,
        //   title: 'Registreren is gelukt!',
        //   description: 'false',
        // });
        this.switchMode('register-success');
      })
      .catch(error => {
        this.switchMode('register');
      });
  }

  handleRegister(event) {
    if (event) event.preventDefault();

    this.register();
  }

  handleInputChange(event, name, value) {
    if (!name) return;
    this.setState({ [name]: value });
  }

  getUsernameInputOptions() {
    return {
      type: 'text',
      value: this.state.username || '',
      name: 'username',
      label: 'Gebruikersnaam',
      callback: this.handleInputChange,
    };
  }

  getPasswordInputOptions() {
    return {
      type: 'password',
      value: this.state.password || '',
      name: 'password',
      label: 'Wachtwoord',
      indicator: true,
      callback: this.handleInputChange,
      eye: true,
    };
  }

  getRegisterFormProps() {
    // let string = '<p></p>';
    // if( this.state.submittedOnce && this.state.password.length <= 7 ) {
    //   this.store.toasters.add({
    //     variant: 'error',
    //     title: "Kies een wachtwoord dat voldoet aan de veiligheidsvoorwaarden (minimaal 8 tekens, waarvan 1 cijfer en 1 teken).",
    //   });
    // }
    return {
      key: this.forms['register'].id,
      title: 'Registreren',
      fields: [
        this.getUsernameInputOptions(),
        {
          ...this.getPasswordInputOptions(),
          indicator: true,
        },
      ],
      actions: [
        {
          text: 'Registreren',
          button: {
            theme: 'alpha',
            type: 'submit',
            disabled:
              !this.state.username ||
              this.state.username === '' ||
              !this.state.password ||
              this.state.password === '',
          },
          ripple: {
            theme: 'alpha',
            size: 'small',
          },
        },
      ],
      callback: this.handleRegister,
    };
  }

  getRegisterSuccessProps() {
    return {
      key: this.forms['register-success'].id,
      title: 'Registratie is gelukt',
      introduction: {
        content: `<p>Uw registratie is gelukt. U kunt nu inloggen.</p>`,
      },
      actions: [
        {
          text: 'Naar inloggen',
          button: {
            theme: 'alpha',
            size: 'small',
            variant: 'forgot',

            callback: event => {
              if (event) event.preventDefault();

              const { replace } = this.props.routing;
              replace('/login');
            },
          },
        },
      ],
    };
  }

  getLoaderWrpClassNames() {
    return clsx(_CLASSES.LOADER.WRP);
  }

  getBackgroundClassNames() {
    return clsx(_CLASSES.PAGE.BACKGROUND);
  }

  getPageClassNames() {
    return clsx(
      _CLASSES.PAGE.MAIN,
      _CLASSES.PAGE.FULLHEIGHT,
      _CLASSES.PAGE.VALIGNCENTER,
      this.getStyleClassNames()
    );
  }

  getStyleClassNames() {
    return clsx(_CLASSES.MAIN);
  }
}

AcRegisterController.propTypes = {};

AcRegisterController.defaultProps = {};

export default AcRegisterController;
