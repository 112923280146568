// Imports => React
import React from 'react';
import { inject, observer } from 'mobx-react';
import { withNamespaces } from 'react-i18next';
import ReactCSSTransitionReplace from 'react-css-transition-replace';

// Imports => Controller
import AcParticipationController from './ac-participation.controller.js';

// Imports => Assets
/* eslint react-app/import/no-webpack-loader-syntax: off */
// import AcLogoSVG from '-!svg-react-loader!@assets/images/realiance-brand-pitch-2x.svg';
import AcLogoVerticalSVG from '-!svg-react-loader!@assets/images/realiance-logo-vertical-1x.svg';
import AcLogoVerticalWhiteSVG from '-!svg-react-loader!@assets/images/realiance-logo-vertical-white-1x.svg';

// Imports => Components
import AcHero from '@components/ac-hero/ac-hero.web.js';
import AcStepper from '@components/ac-stepper/ac-stepper.web.js';

// Imports => Atoms
import { AcContainer, AcRow, AcColumn } from '@atoms/ac-grid';
import AcSection from '@atoms/ac-section/ac-section.web.js';

import AcCard from '@atoms/ac-card/ac-card.web.js';

@inject('routing', 'store')
@observer
class AcParticipation extends AcParticipationController {
  render() {
    const { impersonated } = this.store.auth;
    const { single: Project, loading } = this.store.projects;
    const { current_step } = this.state;

    const _steps = this.getSteps();

    return (
      <div
        className={
          !impersonated
            ? this.getPageClassNames()
            : this.getImpersonateClassNames()
        }
      >
        {!loading && Project !== null && typeof Project !== 'undefined' && (
          <>
            <AcHero
              title={Project.name}
              subtitle={Project.country && Project.country.name}
              image={Project.cover}
            />

            <AcSection theme={'white'}>
              <AcContainer fluid={false}>
                <AcRow>
                  <AcColumn>
                    <AcCard
                      hoverAnimation={false}
                      className={'h-margin-top--180 h-padding-y-50'}
                      theme={
                        current_step === _steps.length - 1 ? 'pitch' : 'default'
                      }
                      id={'ac-participation-form-card'}
                    >
                      <AcContainer fluid={true}>
                        <AcRow noGutters={true} className={'h-padding-y-0'}>
                          <AcColumn xs={12}>
                            {current_step < _steps.length - 1 && (
                              <AcLogoVerticalSVG
                                className={'ac-participation-logo'}
                              />
                            )}
                            {current_step === _steps.length - 1 && (
                              <AcLogoVerticalWhiteSVG
                                className={'ac-participation-logo'}
                              />
                            )}
                          </AcColumn>
                        </AcRow>

                        {current_step < _steps.length - 1 && (
                          <AcRow
                            className={'h-margin-y-50 h-text--align-center'}
                          >
                            <AcColumn>
                              <AcStepper
                                steps={_steps}
                                interactive={false}
                                indexes={true}
                                horizontal={true}
                                className={this.getStepperClassNames()}
                              />
                            </AcColumn>
                          </AcRow>
                        )}

                        <AcRow>
                          <AcColumn>
                            <ReactCSSTransitionReplace
                              transitionName="fade-wait"
                              transitionEnterTimeout={1000}
                              transitionLeaveTimeout={1000}
                              className={this.getFormWrpClassNames()}
                            >
                              {this.renderStep(_steps, current_step)}
                            </ReactCSSTransitionReplace>
                          </AcColumn>
                        </AcRow>
                      </AcContainer>
                    </AcCard>
                  </AcColumn>
                </AcRow>
              </AcContainer>
            </AcSection>
          </>
        )}
      </div>
    );
  }
}

export default withNamespaces()(AcParticipation);
